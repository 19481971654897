import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { IndexedDbUnitService } from '../indexeddb-unit.service';

@Injectable()
export class ConfigGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly indexedDbUnitService: IndexedDbUnitService
  ) {}

  async canActivate() {
    try {
      const unit = await this.indexedDbUnitService.getUnit();
      if (unit) {
        this.router.navigate(['/unit', unit.id, 'poll', unit.pollId]);
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return false;
    }
  }
}
