import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Alert, AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-alert',
  standalone: true,
  template: `
    <div class="alert">
      <div class="content" *ngFor="let alert of alerts; trackBy: trackById">
        <div class="message" [ngClass]="alert.alertType">
          <p *ngIf="alert.title">{{ alert.title }}</p>
          {{ alert.message }}
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .alert {
        position: fixed;
        top: 2rem;
        right: 1rem;
        z-index: 999999;
        max-width: 40rem;
      }

      .alert .content {
        display: flex;
        flex-direction: column;
      }

      .alert .content .message {
        padding: 1rem;
        font-weight: 500;
        color: #fff;
        border-radius: 0.3125rem;
        margin-bottom: 1rem;
      }

      .alert .content .message p {
        font-size: 1.25rem;
      }

      .alert .content .message.success {
        background: #4caf50;
      }

      .alert .content .message.info {
        background: #2196f3;
      }

      .alert .content .message.warning {
        background: #ffeb3b;
        color: #000000;
      }

      .alert .content .message.danger {
        background: #ff5722;
      }

      .alert .content .message:not(:first-child) {
        margin-top: 1rem;
      }
    `,
  ],
  imports: [CommonModule],
})
export class AlertComponent implements OnInit, OnDestroy {
  timeout: number = 6000;
  alerts: Alert[] = [];
  private subscription!: Subscription;

  // AlertService não é opcional no construtor
  constructor(private readonly alertService: AlertService) {}

  ngOnInit(): void {
    // Sem o alertService opcional, não há necessidade de checar null
    this.subscription = this.alertService
      .getAlert()
      .subscribe((alert: Alert | null) => {
        if (alert) {
          this.activateAlert(alert);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  activateAlert(alert: Alert): void {
    this.alerts.unshift(alert);

    setTimeout(() => {
      this.alerts = this.alerts.filter((a) => a.id !== alert.id);
    }, this.timeout);
  }

  trackById(index: number, item: Alert): string | number {
    return item.id;
  }
}
